// Generated by Framer (716dd6f)

import { addFonts, cx, CycleVariantState, Image, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion } from "framer-motion";
import * as React from "react";

const enabledGestures = {P6u7ikDql: {hover: true}};

const cycleOrder = ["P6u7ikDql"];

const variantClassNames = {P6u7ikDql: "framer-v-171ez3o"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const humanReadableVariantMap = {};

const transitions = {default: {damping: 60, delay: 0, duration: 0.3, ease: [0.44, 0, 0.56, 1], mass: 1, stiffness: 500, type: "spring"}};

const BASE62 = "0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz"

function useRandomID(){const ref = React.useRef(null);
if (ref.current === null) {
ref.current = Array(5).fill(0).map(() => BASE62[Math.floor(Math.random() * BASE62.length)]).join("");
}
return ref.current;}

export interface Props extends React.HTMLAttributes<HTMLDivElement> { layoutId?: string | null; width?: number; height?: number; layoutDependency?: string | number; variant?: keyof typeof humanReadableVariantMap; }

const Component = React.forwardRef<HTMLDivElement, Props>(function({ id, style: externalStyle = {}, className, width, height, layoutId, variant: outerVariant = "P6u7ikDql", ...restProps }, ref) {

const outerVariantId = humanReadableVariantMap[outerVariant];

const variant = outerVariantId || outerVariant;

const {baseVariant, classNames, gestureVariant, setGestureState, setVariant, transition, variants} = useVariantState({cycleOrder, defaultVariant: "P6u7ikDql", enabledGestures, transitions, variant, variantClassNames})
const layoutDependency = variants.join("-") + restProps.layoutDependency;

const defaultLayoutId = useRandomID()

const { pointerEvents, ...style } = externalStyle

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<motion.div data-framer-generated initial={variant} animate={variants} onHoverStart={() => setGestureState({isHovered: true})} onHoverEnd={() => setGestureState({isHovered: false})} onTapStart={() => setGestureState({isPressed: true})} onTap={() => setGestureState({isPressed: false})} onTapCancel={() => setGestureState({isPressed: false})} className={cx("framer-RHElG", classNames)} style={{display: "contents", pointerEvents: pointerEvents ?? "auto"}}>
<motion.div {...restProps} className={cx("framer-171ez3o", className)} data-framer-name={"Variant 1"} layoutDependency={layoutDependency} layoutId={"P6u7ikDql"} ref={ref} style={{...style}} transition={transition} {...addPropertyOverrides({"P6u7ikDql-hover": {"data-framer-name": undefined}}, baseVariant, gestureVariant)}><Image background={{alt: "sparetime-intro-sona-design", fit: "fit", intrinsicHeight: 1108, intrinsicWidth: 1001, pixelHeight: 1108, pixelWidth: 1001, src: new URL("assets/e2Zi6tvzYofiOjYPek3UxiXNmk.jpg", import.meta.url).href, srcSet: `${new URL("assets/512/e2Zi6tvzYofiOjYPek3UxiXNmk.jpg", import.meta.url).href} 462w, ${new URL("assets/1024/e2Zi6tvzYofiOjYPek3UxiXNmk.jpg", import.meta.url).href} 925w, ${new URL("assets/e2Zi6tvzYofiOjYPek3UxiXNmk.jpg", import.meta.url).href} 1001w`}} className={"framer-16kztiu"} data-framer-name={"Red route"} layoutDependency={layoutDependency} layoutId={"ELn1mWAQP"} style={{filter: "grayscale(1) drop-shadow(0px 0px 13px rgba(0, 0, 0, 0.13))", WebkitFilter: "grayscale(1) drop-shadow(0px 0px 13px rgba(0, 0, 0, 0.13))"}} transition={transition} variants={{"P6u7ikDql-hover": {filter: "grayscale(0) drop-shadow(0px 0px 13px rgba(0, 0, 0, 0.13))", WebkitFilter: "grayscale(0) drop-shadow(0px 0px 13px rgba(0, 0, 0, 0.13))"}}}/></motion.div>
</motion.div>
</LayoutGroup>)

});

const css = [".framer-RHElG [data-border=\"true\"]::after { content: \"\"; border-width: var(--border-top-width, 0) var(--border-right-width, 0) var(--border-bottom-width, 0) var(--border-left-width, 0); border-color: var(--border-color, none); border-style: var(--border-style, none); width: 100%; height: 100%; position: absolute; box-sizing: border-box; left: 0; top: 0; border-radius: inherit; pointer-events: none; }", "@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-RHElG * { box-sizing: border-box; }", ".framer-RHElG .framer-brobpz { display: block; }", ".framer-RHElG .framer-171ez3o { align-content: center; align-items: center; display: flex; flex-direction: row; flex-wrap: nowrap; gap: 0px; height: min-content; justify-content: center; overflow: hidden; padding: 30px 30px 30px 30px; position: relative; width: 565px; }", ".framer-RHElG .framer-16kztiu { aspect-ratio: 0.9083333333333333 / 1; flex: 1 0 0px; height: var(--framer-aspect-ratio-supported, 556px); max-width: 565px; overflow: hidden; position: relative; width: 1px; }", ".framer-RHElG .framer-v-171ez3o .framer-171ez3o { cursor: pointer; }", "@supports (background: -webkit-named-image(i)) and (not (font-palette:dark)) { .framer-RHElG .framer-171ez3o { gap: 0px; } .framer-RHElG .framer-171ez3o > * { margin: 0px; margin-left: calc(0px / 2); margin-right: calc(0px / 2); } .framer-RHElG .framer-171ez3o > :first-child { margin-left: 0px; } .framer-RHElG .framer-171ez3o > :last-child { margin-right: 0px; } }", ".framer-RHElG.framer-v-171ez3o.hover .framer-16kztiu { height: var(--framer-aspect-ratio-supported, 220px); }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 616
 * @framerIntrinsicWidth 565
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["fixed","auto"]},"Ol50Io1yS":{"layout":["fixed","auto"]}}}
 */
const FramerWwz_zggk6: React.ComponentType<Props> = withCSS(Component, css) as typeof Component;
export default FramerWwz_zggk6;

FramerWwz_zggk6.displayName = "gcoop 2";

FramerWwz_zggk6.defaultProps = {height: 616, width: 565};

addFonts(FramerWwz_zggk6, [])